import { Spin } from 'antd';
import { lazy, Suspense } from 'react';
import { cdn } from './State';

const Companies = lazy(() => import('./Companies'));
const Header = lazy(() => import('./Header'));
const Trailer = lazy(() => import('./Trailer'));

export const App = () => {

  return <div className="h-screen w-screen overflow-auto bg-fixed bg-no-repeat bg-cover" 
    style={{ backgroundImage: `url(${cdn}/storage/sites|gnp-companies|bg/bg_corners.jpg?width=1910)` }}>

    <div className="bg-black/60 lg:bg-black/80 text-gray-200 min-h-screen">
      <Suspense fallback={<Spin spinning={true} />}>
        <Header />
        <Companies />
        <Trailer />
      </Suspense>
    </div>
  </div>;
}

export default App;